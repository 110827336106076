import React, { Component } from 'react'

export default class Admins extends Component {
    render() {
        return (
            <div className="bg-zinc-800 bg-opacity-30 rounded-2xl mt-4 ml-2 text-white p-4 text-opacity-70 text-sm">
                <p className="bg-red-800 bg-opacity-40 rounded-lg p-2 mb-2">За нарушение почти любого правила следует снятие привилегии или же снимающий может сам установить наказание, на своё усмотрение. Не превышайте полномочий, наказывать привилегированных игроков может только куратор и выше.</p>
                <p className="text-lg p-1">1. Правила VIP-игрокам:</p>
                <p className="pl-4">a. Даже если вы и приобрели Вип статус, то это не делает вас выше других.</p>
                <p className="pl-4">b. Запрещено кикать кого-либо без объективной причины.</p>
                <p className="text-lg p-1">2. Правила администраторам:</p>
                <p className="pl-4">a. Вы не освобождаетесь от вышеназванных правил.</p>
                <p className="pl-4">b. Запрещено злоупотребление привилегией (злоупотребление командами, статусом).</p>
                <p className="pl-4">c. Банить кого-либо без объективной причины запрещено.</p>
                <p className="pl-4">d. Вы обязаны следовать срокам наказания из правил.</p>
                <p className="pl-4">e. Если Игрок нарушили два или более правил, вы должны суммировать срок наказания.</p>
                <p className="pl-4">f. Игрок, нарушивший два правила с киком, получает бан на 5 минут.</p>
                <p className="pl-4">g. Если Вы кого-то забанили, то Вы обязаны предоставить доказательства правомерности ваших действий, если того попросит наказуемый, иначе наказание игрока будет аннулировано, а Вы сами получите наказание (вплоть до снятия привилегии).</p>
                <p className="pl-4">h. Запрещено снимать наказание другого администратора.</p>
                <p className="pl-4">i. Кик без объективной причины запрещён.</p>
                <p className="pl-4">j. Запрещена передача аккаунта третьим лицам.</p>
                <p className="pl-4">k. Без каких-либо ограничений можно выдавать из оружейки (weaponmenu) ssg08 (муха).</p>
                <p className="pl-4">l. Гранаты из оружейки можно брать только на картах, на которых они есть, и в умеренном количестве.</p>
                <p className="pl-4">m. Если вы хотите взять какое-либо оружие (кроме ssg08), то с этим должны быть согласны все игроки, и если уже 2 человека не согласны, то вы не можете взять оружие.</p>
                <p className="pl-4">n. На паутинке можно летать только в конце раунда, или не мешая при этом игрокам (не злоупотребляйте).</p>
                <p className="pl-4">o. Запрещено злоупотребление грабом.</p>
                <p className="pl-4">p. Запрещено злоупотребление командами чата (msay, hsay и т.д.).</p>
                <p className="pl-4">q. Администратор не может перевести игрока из одной команды в другую без его согласия, если это не нужно для баланса команд.</p>
                <p className="pl-4">r. Администратор должен следить за балансом между командами.</p>
                <p className="pl-4">s. Если Вы проводите голосование, то оно считается успешным, если набрало более 65% голосов.</p>
                <p className="pl-4">t. Вы не можете сменить карту по собственному желанию, необходимо провести голосование согласно пункту 2s.</p>
                <p className="pl-4">u. Запрещено вмешиваться в игровой процесс с помощью команд привилегии (Ноуклип и тому подобное). (Бан на сутки или снятие)</p>
                <p className="pl-6 text-green-500 text-opacity-80">i. Можно пользоваться данными командами только после окончания раунда.</p>
                <p className="text-lg p-1">3. Правила Младшим кураторам:</p>
                <p className="pl-4">a. Младший куратор не освобождается от вышеназванных правил.</p>
                <p className="pl-4">b. Младший куратор обязан играть на сервере каждый день (до момента получения статуса "Куратор").</p>
                <p className="pl-4">c. Младший куратор обязан подтверждать доказательствами каждое вынесенное им наказание.</p>
                <p className="pl-4">d. Младший куратор обязан помогать игрокам серверов, независимо от его отношения к ним.</p>
                <p className="pl-4">e. Младший куратор обязан слушаться вышестоящую администрацию проекта.</p>
                <p className="text-lg p-1">4. Правила Кураторам:</p>
                <p className="pl-4">a. Куратор не освобождается 1-3 пунктов вышеназванных правил.</p>
                <p className="pl-4">b. Куратор не имеет право устанавливать правила на постоянной основе.</p>
                <p className="pl-4">c. Куратор имеет право устанавливать временные правила (если с этими правилами согласны 65% игроков; правило перестаёт действовать с момента выхода куратора с сервера).</p>
                <p className="pl-4">d. Куратор обязан играть на сервере раз в два-три дня.</p>
                <p className="pl-4">e. Куратор обязан предоставить доказательства объективности вынесенной им санкции при необходимости.</p>
                <p className="text-lg p-1">5. Правила Старшим кураторам:</p>
                <p className="pl-4">a. Старший куратор имеет полную власть над всеми остальными привилегиями.</p>
                <p className="pl-4">b. Старший куратор имеет право проводить набор в кураторский состав проекта.</p>
                <p className="pl-4">c. Старший куратор не обязан предоставлять какие-либо доказательства для своих действий.</p>
                <p className="pl-4">d. Старший куратор имеет право на вмешательство в игровой процесс на свое усмотрение.</p>
                <p className="pl-4">e. Старший куратор имеет право на изменение данных игрока. (привилегии, кредитов, бонусов)</p>
            </div>
        )
    }
}
