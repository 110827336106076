import React, {Fragment} from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import {CogIcon, LibraryIcon, LogoutIcon, MenuIcon, UserIcon} from '@heroicons/react/outline'
import { Link } from 'react-router-dom'

const navigation = [
    { name: 'Главна', to: '/', current: false },
    { name: 'Рейтинг', to: '/top', current: false },
    { name: 'Бан лист', to: '/bans', current: false },
    { name: 'Мут лист', to: '/muts', current: false },
    { name: 'Информация', to: '/info', current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const Nav = ({openMenu}) => {
    return (
      <Disclosure as="nav" className="fixed w-full bottom-0 sm:top-0 sm:bottom-auto nav navv">
        {({ open }) => (
          <>
            <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
              <div className="relative flex items-center justify-between h-16">
                <div className="absolute inset-y-0 left-0 flex items-center sm:hidden">
                  {/* Mobile menu button*/}
                        <Disclosure.Button onClick={openMenu} className="inline-flex items-center justify-center p-2 rounded-md text-white nav-it">
                            <span className="sr-only">Open main menu</span>
                            <MenuIcon className="block h-6 w-6" aria-hidden="true" />
                        </Disclosure.Button>
                </div>
                <div className="flex-1 flex items-center justify-center sm:items-stretch sm:justify-start">
                  <div className="flex-shrink-0 flex items-center text-slate-50 font-mono text-xl">
                      FearStrike
                  </div>
                  <div className="hidden sm:block sm:ml-6">
                    <div className="flex space-x-4">
                      {navigation.map((item) => (
                        <Link
                          key={item.name}
                          to={item.to}
                          className={classNames(
                            item.current ? 'nav-it text-white' : 'text-gray-300 hover:nav-it-hov hover:text-white hover:bg-purple-500 hover:shadow-md hover:shadow-purple-500 duration-500',
                            'px-3 py-2 rounded-md text-sm font-medium'
                          )}
                          aria-current={item.current ? 'page' : undefined}
                        >
                          {item.name}
                        </Link>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0">

                  {/* Index dropdown */}
                  <Menu as="div" className="ml-3 relative">
                    <div>
                      <Menu.Button className="bg-gray-800 flex text-sm rounded-full">
                        <span className="sr-only">Open user menu</span>
                        <img
                          className="h-10 w-10 rounded-full"
                          src="https://steamcdn-a.akamaihd.net/steamcommunity/public/images/avatars/96/9642dc1c050a2cee8ccfacf88635d4ec4c500d14_full.jpg"
                          alt=""
                        />
                      </Menu.Button>
                    </div>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="origin-top-right absolute right-0 sm:mt-2 -mt-48 rounded-md shadow-lg shadow-purple-500 py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                              <Link to="/profile" className="px-3 py-2 font-medium flex space-x-2 hover:bg-purple-500 hover:shadow-lg hover:shadow-purple-500 duration-500"><UserIcon className="block h-6 w-6 opacity-50" aria-hidden="true" /><p>Профиль</p></Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                              <Link to="/panel" className="px-3 py-2 font-medium flex space-x-2 hover:bg-purple-500 hover:shadow-lg hover:shadow-purple-500 duration-500"><LibraryIcon className="block h-6 w-6 opacity-50" aria-hidden="true" /><p>Управление</p></Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                              <Link to="/settings" className="px-3 py-2 font-medium flex space-x-2 hover:bg-purple-500 hover:shadow-lg hover:shadow-purple-500 duration-500"><CogIcon className="block h-6 w-6 opacity-50" aria-hidden="true" /><p>Настройки</p></Link>
                          )}
                        </Menu.Item>
                        <Menu.Item>
                          {({ active }) => (
                              <Link to="/exit" className="px-3 py-2 font-medium flex space-x-2 hover:bg-purple-500 hover:shadow-lg hover:shadow-purple-500 duration-500"><LogoutIcon className="block h-6 w-6 opacity-50" aria-hidden="true" /><p>Выход</p></Link>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
              </div>
            </div>
          </>
        )}
      </Disclosure>
    )
  }

export default Nav;