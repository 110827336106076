import React, { Component } from 'react'

export default class Awp extends Component {
    render() {
        return (
            <div className="bg-zinc-800 bg-opacity-30 rounded-2xl mt-4 ml-2 text-white p-4 text-opacity-70 text-sm">
                <p className="text-lg p-1">Вип:</p>
                <p className="pl-4">- Кик</p>
                <p className="pl-4">- Неоновая подсветка</p>
                <p className="pl-4">- Монетка в табе</p>
                <p className="pl-4">- Электрические эффекты при выстреле</p>
                <p className="pl-4">- Растворение тела</p>
                <p className="pl-4">- Нет урона от падений</p>
                <p className="pl-4">- Банихоп / Двойной прыжок / Высокий прижок</p>
                <p className="pl-4">- Отображение попаданий по противнику</p>
                <p className="pl-4">- Пейнтбол</p>
                <p className="pl-4">- Регенерация хп</p>
                <p className="pl-4">- Моделька персонажа из Left4Dead</p>
                <p className="text-lg p-1">Вип+:</p>
                <p className="pl-4">- Все что может Вип</p>
                <p className="pl-4">- Выше иммунитет чем у вип</p>
                <p className="pl-4">- Возрождение 1 раз в раунд</p>
                <p className="text-lg p-1">Админ:</p>
                <p className="pl-4">- Все что может Вип+</p>
                <p className="pl-4">- Выше иммунитет чем у вип+</p>
                <p className="pl-4">- Стандартные возможности админа (кик, бан, мут и т.д.)</p>
                <p className="pl-4">- Возрождение 1 раз в раунд</p>
                <p className="pl-4">- Паутинка</p>
                <p className="pl-4">- Граб (перетаскивать людей и предметы)</p>
                <p className="pl-4">- Оружейка (!weaponmenu)</p>
                <p className="pl-4">- Моделька персонажа из крайзис</p>
                <p className="text-lg p-1">Админ+:</p>
                <p className="pl-4">- Все что может Админ</p>
                <p className="pl-4">- Выше иммунитет чем у Админ</p>
                <p className="pl-4">- Моделька персонажа Дедпул</p>
                <p className="text-lg p-1">Админ++:</p>
                <p className="pl-4">- Все что может Админ+</p>
                <p className="pl-4">- Выше иммунитет чем у Админ+</p>
                <p className="pl-4">- Моделька персонажа Юшино</p>
                <p className="pl-4">- Возрождение 2 раза в раунд</p>
                <p className="text-lg p-1">Hydra:</p>
                <p className="pl-4">- Все что может Админ++</p>
                <p className="pl-4">- Выше иммунитет чем у Админ++</p>
                <p className="pl-4">- Заморозка с шансом 65%</p>
                <p className="pl-4">- Лечение союзников попаданиями</p>
                <p className="pl-4">- Лечащий куб !healcube</p>
                <p className="pl-4">- Отображение нанесенного урона</p>
                <p className="text-lg p-1">Hydra+:</p>
                <p className="pl-4">- Все что может Hydra</p>
                <p className="pl-4">- Выше иммунитет чем у Hydra</p>
                <p className="pl-4">- Ускорение перезарядки</p>
                <p className="pl-4">- Моделька персонажа foxmiku</p>
                <p className="text-lg p-1">Hydra++:</p>
                <p className="pl-4">- Все что может Hydra+</p>
                <p className="pl-4">- Выше иммунитет чем у Hydra+</p>
                <p className="pl-4">- Возрождение 3 раза в раунд</p>
                <p className="pl-4">- Все возможные модельки на персонажа</p>
                <p className="text-lg p-1">Бог:</p>
                <p className="pl-4">- Все что может Hydra++</p>
                <p className="pl-4">- Выше иммунитет чем у Hydra++</p>
                <p className="pl-4">- Возрождение 4 раза в раунд</p>
            </div>
        )
    }
}
