import React, { Component } from 'react'

export default class Main extends Component {
  render() {
    return (
      <div className="bg-zinc-800 bg-opacity-30 rounded-2xl mt-4 ml-2 text-white p-4 text-opacity-70 text-sm">
        <p className="text-lg p-1">1. Информация по сайту</p>
        <p className="pl-4">Данный сайт на котором вы сейчас находитесь, принадлежит проекту FearStrike.</p>
        <p className="pl-4">На сайте вы можете:</p>
        <p className="pl-5">1. Посмотреть свою игровую информацию.</p>
        <p className="pl-5">2. Посмотреть игровую информацию о других игроках.</p>
        <p className="pl-5">3. Настроить свой игровой профиль.</p>
        <p className="pl-5">4. Получить награду за опредленные действия.</p>
        <p className="text-lg p-1">2. Ошибка Missing map</p>
        <p className="pl-4">Если у вас при заходе на сервер с нестандартной картой появляется такое сообщение - Missing map:</p>
        <p className="pl-4">То возможные методы решения:</p>
        <p className="pl-5">1) Удаление старой карты:</p>
        <p className="pl-6">- Попробуйте найти эту карту в папке C:\Program Files (x86)\Steam\SteamApps\common\Counter-Strike Global Offensive\csgo\maps, удалить ТОЛЬКО эту карту, перезайти.</p>
        <p className="pl-6">- Удалить содержимое папки - C:\Program Files (x86)\Steam\SteamApps\common\Counter-Strike Global Offensive\csgo\cache</p>
        <p className="pl-6">- Удалить содержимое папки - C:\Program Files (x86)\Steam\SteamApps\common\Counter-Strike Global Offensive\csgo\downloads</p>
        <p className="pl-5">2) Проверка настроек:</p>
        <p className="pl-6">- набрать в консоле игры: cl_allowdownload 1</p>
        <p className="pl-5">3) Удивительное рядом:</p>
        <p className="pl-6">Проблема может быть из-за internet explorer (включен автономный режим или прокси).</p>
        <p className="pl-6">Заходим в настройки IE (справа на панели пункт Сервис) и отключаем прокси или автономный режим.</p>
        <p className="pl-5">Все файлы серверов вы можете скачать тут:</p>
        <p className="pl-6"><a href="https://arena.fearstrike.com/" target="_blank" rel="noreferrer" className="hover:text-purple-500 duration-500 text-white text-opacity-80">Арена 1vs1</a></p>
        <p className="pl-6"><a href="https://awp.fearstrike.com/" target="_blank" rel="noreferrer" className="hover:text-purple-500 duration-500 text-white text-opacity-80">Снайперы Lego</a></p>
        <p className="pl-6"><a href="https://mirage.fearstrike.com/" target="_blank" rel="noreferrer" className="hover:text-purple-500 duration-500 text-white text-opacity-80">Mirage Only</a></p>
        <p className="pl-5">Только не забывайте распаковывать архивы перед залитием в папку с игрой!</p>
      </div>
    )
  }
}
