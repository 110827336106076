import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Account from "./tabs/account";
import Stat from "./tabs/stat";
import Rewards from "./tabs/rewards";
import Blocks from "./tabs/blocks";
import History from "./tabs/history";
// var progress = {
//     width: '62%'
// }

const Index = () => {
    return (
        <div>
            <div className="w-full h-64 bgprof container mx-auto rounded-b-3xl sm:rounded-3xl border border-purple-700">
                <div className="rounded-b-3xl sm:rounded-3xl bggrd w-full h-full">
                    <div className="absolute mt-32 mr-auto bg-zinc-800 bg-opacity-50 rounded-3xl w-80">
                        <img src="img/profile.jpg" alt="Аватарка" className="rounded-3xl w-32"/>
                        <div className="p-3 pt-4 -mt-32 ml-32 w-48">
                            <div className="text-left text-white">
                                <div className="text-md">Ded</div>
                                <div className="text-opacity-60 text-white text-sm pb-2 border-b border-opacity-50 border-purple-700">В разработке</div>
                                <div className="grid grid-cols-3 text-opacity-60 text-white">
                                    <div className="absolute">LVL</div>
                                    <div className="ml-8">Бонусы</div>
                                    <div className="ml-10">Кредиты</div>
                                </div>
                                <div className="grid grid-cols-3">
                                    <div className="absolute">24</div>
                                    <div className="ml-8">102334</div>
                                    <div className="ml-10">200425</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mt-4 container mx-auto">
                <Tabs className="rounded-3xl rounded-t-2xl grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 sm:space-x-4" selectedTabClassName="bg-purple-700 bg-opacity-50">
                    <TabList className="col-span-1 text-center space-y-3 m-2 mb-4">
                        <Tab className="rounded-lg border border-purple-800 border-opacity-50 shadow-sm shadow-purple-800 text-lg text-white text-slate-400 hover:bg-purple-700 hover:bg-opacity-50 duration-500 cursor-pointer">
                            <div>Данные об аккаунте</div>
                        </Tab>
                        <Tab className="rounded-lg border border-purple-800 border-opacity-50 shadow-sm shadow-purple-800 text-lg text-white text-slate-400 hover:bg-purple-700 hover:bg-opacity-50 duration-500 cursor-pointer">
                            <div>Статистика игрока</div>
                        </Tab>
                        <Tab className="rounded-lg border border-purple-800 border-opacity-50 shadow-sm shadow-purple-800 text-lg text-white text-slate-400 hover:bg-purple-700 hover:bg-opacity-50 duration-500 cursor-pointer">
                            <div>Награды</div>
                        </Tab>
                        <Tab className="rounded-lg border border-purple-800 border-opacity-50 shadow-sm shadow-purple-800 text-lg text-white text-slate-400 hover:bg-purple-700 hover:bg-opacity-50 duration-500 cursor-pointer">
                            <div>История блокировок</div>
                        </Tab>
                        <Tab className="rounded-lg border border-purple-800 border-opacity-50 shadow-sm shadow-purple-800 text-lg text-white text-slate-400 hover:bg-purple-700 hover:bg-opacity-50 duration-500 cursor-pointer">
                            <div>История транзакций</div>
                        </Tab>
                    </TabList>
                    <div className="col-span-2 lg:col-span-4 ">
                        <TabPanel>
                            <Account />
                        </TabPanel>
                        <TabPanel>
                            <Stat />
                        </TabPanel>
                        <TabPanel>
                            <Rewards />
                        </TabPanel>
                        <TabPanel>
                            <Blocks />
                        </TabPanel>
                        <TabPanel>
                            <History />
                        </TabPanel>
                    </div>
                </Tabs>
            </div>
        </div>
    );
};

export default Index;