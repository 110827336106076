import React, { Component } from 'react'

export default class Binds extends Component {
  render() {
    return (
      <div className="bg-zinc-800 bg-opacity-30 rounded-2xl mt-4 ml-2 text-white p-4 text-opacity-70 text-sm">
        <p className="text-lg text-red-800 p-1">Обратите внимение! Не на всех серверах эти команды работают!</p>
        <p className="text-lg p-1">Что такое бинды и для чего они нужны???</p>
        <p className="pl-4">Бинд – происходит от английского слова bind, что в переводе означает привязывать.</p>
        <p className="pl-4">Что такое бинд? Это привязка наиболее часто используемых команд к одной или нескольким кнопкам клавиатуры. Как правило, это могут быть заранее заготовленные сообщения в чат.</p>
        <p className="text-lg p-1">Как его использовать?</p>
        <p className="pl-4">Использовать бинды достаточно просто.</p>
        <p className="pl-4">Достаточно просто открыть консоль клавишей ~, и прописать определенную команду.</p>
        <p className="pl-4">bind Клавиша команда</p>
        <p className="pl-4">Для примера вот бинд, который позволяет привязать прыжок к пробелу - "space"</p>
        <p className="pl-4">bind space +jump</p>
        <p className="text-lg p-1">Список биндов для сервера:</p>
        <p className="pl-4 text-green-800 p-1">Наведи курсор на бинд и появится дополнительная информация.</p>
        <p className="pl-4" title="Паутинка">bind "?" +hook</p>
        <p className="pl-4" title="Крюк для перетаскивания">bind "?" +grab</p>
        <p className="pl-4" title="Рисовалка на стенах">bind "?" +paint</p>
        <p className="pl-4" title="Восстанавливающая хп заморозка">bind "?" sm_healcube</p>
        <p className="pl-4" title="Использовать аптечку">bind "?" "say !medic"</p>
        <p className="pl-4" title="Меню админа">bind "?" "say !admin"</p>
        <p className="pl-4" title="Меню випа">bind "?" "say !vip"</p>
        <p className="pl-4" title="Возрождение">bind "?" "say !respawn"</p>
        <p className="pl-4" title="Оружейное меню">bind "?" "say !weaponmenu"</p>
        <p className="text-md p-1">Если использовать say /.... вместо say !... то команды которые вы написали не будут отображаться другим игрокам.</p>
      </div>
    )
  }
}
