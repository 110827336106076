import React from 'react';
import Monitoring from './components/monitoring';
import Usertop from "./components/usertop";
import Userpost from "./components/userpost";
import axios from 'axios';

class Index extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          error: null,
          isLoaded: false,
          items: []
        };
      }
    
      componentDidMount() {
        axios.get(`https://api.fearstrike.com/main`)
          .then(
            (result) => {
              this.setState({
                isLoaded: true,
                items: result.data
              });
            },
            (error) => {
              this.setState({
                isLoaded: true,
                error
              });
            }
          )
          setInterval(() => {
            axios.get(`https://api.fearstrike.com/main`)
            .then(
              (result) => {
                this.setState({
                  items: result.data
                });
              }
            )
          }, 60 * 1000 );
      }

    render() {
        return (
            <div className="container mx-auto grid grid-cols-1 sm:grid-cols-11 sm:space-x-4 space-y-4 pl-2 pr-2">
                <Monitoring error={this.state.error} isLoaded={this.state.isLoaded} items={this.state.items.monitoring}/>
                <Userpost error={this.state.error} isLoaded={this.state.isLoaded} items={this.state.items.info}/>
                <Usertop error={this.state.error} isLoaded={this.state.isLoaded} items={this.state.items.top}/>
            </div>
        );
    }
  }

export default Index;