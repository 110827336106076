import React, { Component } from 'react'

export default class Players extends Component {
    render() {
        return (
            <div className="bg-zinc-800 bg-opacity-30 rounded-2xl mt-4 ml-2 text-white p-4 text-opacity-70 text-sm">
                <p className="text-lg p-1">1. Основные</p>
                <p className="pl-4">a. Запрещено выдавать себя за администратора проекта, если вы таковым не являетесь. <span className="text-red-500 text-opacity-80">(бан на срок от 4 часов до 3 месяцев)</span></p>
                <p className="pl-4">b. Запрещено использовать ники администраторов и похожие на них. <span className="text-red-500 text-opacity-80">(бан на срок от 4 часов до 3 месяцев)</span></p>
                <p className="pl-4">c. Запрещено игнорирование администрации сервера. <span className="text-red-500 text-opacity-80">(бан на срок от 4 часов до 3 месяцев)</span></p>
                <p className="pl-4">d. Запрещено вводить администрацию проекта в заблуждение. <span className="text-red-500 text-opacity-80">(бан на срок от 4 часов до 3 месяцев)</span></p>
                <p className="pl-4">e. Запрещено выдавать себя за других игроков проекта. <span className="text-red-500 text-opacity-80">(бан на срок от 4 часов до 3 месяцев)</span></p>
                <p className="text-lg p-1">2. Сторонний софт, скрипты и баги</p>
                <p className="pl-4">a. Запрещено использование и хранение стороннего софта, дающего вам любые преимущества над другими игроками. <span className="text-red-500 text-opacity-80">(вечный бан)</span></p>
                <p className="pl-4">b. Запрещено использование скриптов, дающих вам любые преимущества над другими игроками. <span className="text-red-500 text-opacity-80">(бан на срок от 4 часов до 3 месяцев)</span></p>
                <p className="pl-4">c. Запрещена распрыжка с помощью скриптов. <span className="text-red-500 text-opacity-80">(бан на срок от 4 часов до 3 месяцев)</span></p>
                <p className="pl-4">d. Запрещено использование багов карты, дающих вам любые преимущества над другими игроками. <span className="text-red-500 text-opacity-80">(кик, бан на срок от 10 минут до суток)</span></p>
                <p className="text-lg p-1">3. Общение</p>
                <p className="pl-4">a. Нецензурная лексика не запрещается, но просим вас не употреблять её слишком часто. <span className="text-red-500 text-opacity-80">(мут/гаг на срок от 5 минут до 1 часа)</span></p>
                <p className="pl-4">b. Запрещено оскорблять кого-либо на сервере (включая клантег и все остальные виды оскорблений) <span className="text-red-500 text-opacity-80">(мут/гаг на срок от 5 минут до суток)</span></p>
                <p className="pl-6 text-green-500 text-opacity-80">i. Если вас оскорбили, вы можете оскорбить в ответ</p>
                <p className="pl-4">c. Запрещено флудить, спамить, издавать раздражающие звуки в голосовой и обычный чат. <span className="text-red-500 text-opacity-80">(мут/гаг на срок от 5 минут до суток)</span></p>
                <p className="pl-4">d. Запрещено говорить в голосовой и обычный чат, когда 2 игрока остались 1 на 1. <span className="text-red-500 text-opacity-80">(мут/гаг на срок от 5 минут до суток)</span></p>
                <p className="pl-4">e. Запрещена реклама сторонних серверов и сайтов этих серверов. Исключение сервера проекта FearStrike. <span className="text-red-500 text-opacity-80">(мут/гаг на срок от суток до недели)</span></p>
                <p className="pl-6 text-green-500 text-opacity-80">i. Упоминание в контексте допускается, но не сторонних серверов и сайтов этих серверов.</p>
                <p className="pl-4">f. Запрещено использовать программы для изменения голоса. (включая прграммы для воспроизведения звуков) <span className="text-red-500 text-opacity-80">(мут/гаг на срок от 5 минут до суток)</span></p>
                <p className="pl-4">g. Запрещён шантаж в каком-либо виде. <span className="text-red-500 text-opacity-80">(мут/гаг навсегда)</span></p>
                <p className="pl-4">h. Запрещена клевета. <span className="text-red-500 text-opacity-80">(мут/гаг на срок от 5 минут до суток)</span></p>
                <p className="pl-4">i. Запрещено говорить в голосовой и обычный чат во время clutch-ситуации (если вас убили). <span className="text-red-500 text-opacity-80">(мут/гаг на срок от 5 минут до суток)</span></p>
                <p className="pl-4">o. Запрещено обобщение и оскорбление личной жизни игроков. <span className="text-red-500 text-opacity-80">(от мута/гага на сутки до бана на неделю)</span></p>
                <p className="text-lg p-1">4. Игровой процесс</p>
                <p className="pl-6 text-green-500 text-opacity-80">Если вы заметили того, кто нарушает правила, просьба сообщить в сообщения группы или администраторам (не забудьте про доказательства).</p>
                <p className="pl-4">a. Запрещается вымогательство и попрошайничество. <span className="text-red-500 text-opacity-80">(мут/гаг на срок от 5 минут до суток)</span></p>
                <p className="pl-4">b. Запрещено сидеть в закрытую на башне на картах awp_lego_2 и подобных других. <span className="text-red-500 text-opacity-80">(кик, при повторном бан до 30 минут)</span></p>
                <p className="pl-4">c. Запрещено сообщать информацию о противнике пока вы мертвы. <span className="text-red-500 text-opacity-80">(мут/гаг на срок от 5 минут до суток)</span></p>
                <p className="pl-4">d. Запрещено продавать кредиты из магазина. <span className="text-red-500 text-opacity-80">(бан на срок от 4 часов до 3 месяцев)</span></p>
                <p className="pl-4">e. Запрещено резать игроков, при этом используя BunnyHop. <span className="text-red-500 text-opacity-80">(кик, при повторном бан до 30 минут)</span></p>
                <p className="pl-4">f. Запрещено находиться на стенах карт AWP сервера до окончания раунда. <span className="text-red-500 text-opacity-80">(кик, при повторном бан до 30 минут)</span></p>
                <p className="pl-4">g. Игроки, получившие возможность воскрешения, могут респауниться только тогда, когда в каждой из команд не менее 3 живых игроков. <span className="text-red-500 text-opacity-80">(кик, при повторном бан до 30 минут)</span></p>
                <p className="pl-4">h. Запрещено заключать миры (т.е. намеренно не убивать друг друга), то бишь тянуть время раунда. <span className="text-red-500 text-opacity-80">(кик, при повторном бан до 30 минут)</span></p>
                <p className="pl-4">i. Рулетки разрешены только тогда, когда остаётся 1 на 1 или 1 на 2 игрока(ов). В других случаях это рассматривается как нарушение пункта 4.h <span className="text-red-500 text-opacity-80">(кик, при повторном бан до 30 минут)</span></p>
                <p className="pl-4">j. Запрещено препятствовать работе администраторов (выдача их,когда они скрываются и т.д.). <span className="text-red-500 text-opacity-80">(кик, при повторном бан до 30 минут)</span></p>
                <p className="pl-4">k. Запрещено использование оскорбительных никнеймов. <span className="text-red-500 text-opacity-80">(кик, при повторном бан до 30 минут)</span></p>
                <p className="pl-4">l. Запрещено намеренное понижение звания другим игрокам, например: обнуление статистики, игра со вторых аккаунтов и т.д. <span className="text-red-500 text-opacity-80">(бан на срок от 4 часов до 3 месяцев)</span></p>
                <p className="pl-4">m. Запрещен мультиаккаунт. <span className="text-red-500 text-opacity-80">(бан на срок от 4 часов до 3 месяцев)</span></p>
                <p className="pl-4">n. Запрещено намеренное нарушение балланса между командами. <span className="text-red-500 text-opacity-80">(бан на срок от 4 часов до 3 месяцев)</span></p>
            </div>
        )
    }
}
