import React, { Component } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Arena from './arena';
import Awp from './awp';
import Mirage from './mirage';

export default class Priv extends Component {
  render() {
    return (
      <Tabs className="" selectedTabClassName="bg-purple-700 bg-opacity-50">
        <TabList className="grid sm:grid-cols-3 text-center sm:pl-0 sm:pr-0 pl-2 pr-2 space-y-2 sm:space-y-0 sm:space-x-2 sm:ml-2">
          <Tab className="rounded-lg border border-purple-800 border-opacity-50 shadow-sm shadow-purple-800 text-md text-white text-slate-400 hover:bg-purple-700 hover:bg-opacity-50 duration-500 cursor-pointer">
            <div>Арена 1vs1</div>
          </Tab>
          <Tab className="rounded-lg border border-purple-800 border-opacity-50 shadow-sm shadow-purple-800 text-md text-white text-slate-400 hover:bg-purple-700 hover:bg-opacity-50 duration-500 cursor-pointer">
            <div>Снайперы Lego</div>
          </Tab>
          <Tab className="rounded-lg border border-purple-800 border-opacity-50 shadow-sm shadow-purple-800 text-md text-white text-slate-400 hover:bg-purple-700 hover:bg-opacity-50 duration-500 cursor-pointer">
            <div>Mirage Only</div>
          </Tab>
        </TabList>
        <div className="col-span-2 lg:col-span-4 ">
          <TabPanel>
            <Arena/>
          </TabPanel>
          <TabPanel>
            <Awp/>
          </TabPanel>
          <TabPanel>
            <Mirage/>
          </TabPanel>
        </div>
      </Tabs>
    )
  }
}
