import React from 'react';
import axios from 'axios';

class Footer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          error: null,
          isLoaded: false,
          items: []
        };
      }
    
      componentDidMount() {
        axios.get(`https://api.fearstrike.com/footer`)
          .then(
            (result) => {
              this.setState({
                isLoaded: true,
                items: result.data
              });
            },
            (error) => {
              this.setState({
                isLoaded: true,
                error
              });
            }
          )
      }

    render() {
        return (
            <div className="bottom-0 text-center min-h-screen sm:min-h-full mt-20">
                <div className="shadow-lg shadow-purple-900 bg-purple-800">
                    <div className="container mx-auto px-4">
                        <div className="w-full flex text-white text-xs grid grid-cols-1 sm:grid-cols-7 pt-2 pb-2">
                            <div>Прошли регистрацию: {this.state.items.registered}</div>
                            <div>VIP игроков: {this.state.items.vip}</div>
                            <div>Админов: {this.state.items.admin}</div>
                            <div>Игроков на сервере: {this.state.items.players}</div>
                            <div>Мутов: {this.state.items.muts}</div>
                            <div>Банов: {this.state.items.bans}</div>
                            <div>В беседе VK: {this.state.items.vk}</div>
                        </div>
                    </div>
                </div>
                <div className="w-full h-96 grid grid-cols-1 sm:grid-cols-3 container mx-auto pt-6 text-slate-400">
                    <div>
                        <div className="animate-bounce text-slate-50 font-mono text-xl">FearStrike</div>
                        <div><a href="https://ru.reactjs.org/" rel="noreferrer" className="text-purple-600 hover:text-purple-400 duration-500" >React</a> + <a href="https://laravel.ru/" target="_blank" rel="noreferrer" className="text-purple-600 hover:text-purple-400 duration-500">Laravel</a> powered by <a href="https://vk.com/fearst31" target="_blank" rel="noreferrer" className="text-purple-600 hover:text-purple-400 duration-500">nomixe</a></div>
                        <div>Copyright © 2017-2023</div>
                    </div>
                    <div>
                        <div>СОЦИАЛЬНЫЕ СЕТИ</div>
                        <div className="p-3">
                            <a className="rounded-xl border p-1 border-purple-600 text-purple-600 hover:text-purple-400 duration-500" href="https://vk.com/fearstrike" target="_blank" rel="noreferrer">Вконтакте</a>
                            <a className="rounded-xl border p-1 border-purple-600 text-purple-600 hover:text-purple-400 duration-500 ml-1 mr-1" href="https://discord.gg/4GRBDH2" target="_blank" rel="noreferrer">Discord</a>
                            <a className="rounded-xl border p-1 border-purple-600 text-purple-600 hover:text-purple-400 duration-500" href="https://vk.me/join/AJQ1d5rZ3AOmYAG9FucNa59f" target="_blank" rel="noreferrer">Беседа VK</a>
                        </div>
                    </div>
                    <div>
                        <div>ТЕХ. ПОДДЕРЖКА</div>
                        <div className="p-2">
                            <div>Создатель: <a className="text-purple-600 hover:text-purple-400 duration-500" href="https://vk.com/fearst31" target="_blank" rel="noreferrer">Андрей Карпов</a></div>
                            <div>Ст. Куратор: <a className="text-purple-600 hover:text-purple-400 duration-500" href="https://vk.com/beyaoydy" target="_blank" rel="noreferrer">Антон Беловодский</a></div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
  }

export default Footer;