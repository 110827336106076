import React from 'react';
import {Link} from "react-router-dom";
import {HomeIcon, LogoutIcon, UserIcon, LibraryIcon, CogIcon, PresentationChartBarIcon, BanIcon, VolumeOffIcon, InformationCircleIcon} from '@heroicons/react/outline';

const Menu = ({openMenu}) => {
    return (
        <div>
            <div className="space-y-3 mt-4 mhmenu">
                <div className="flex items-center text-white bg-zinc-900 space-x-4 shadow-md shadow-purple-900">
                    <img src="https://steamcdn-a.akamaihd.net/steamcommunity/public/images/avatars/96/9642dc1c050a2cee8ccfacf88635d4ec4c500d14_full.jpg" alt="Аватарка" className="rounded-r-full w-24"/>
                    <div className="grid">
                        <strong>Ded</strong>
                        <span>В разработке!</span>
                    </div>
                </div>
                <div className="flex items-center text-white bg-zinc-900 space-x-4 shadow-md shadow-purple-900">
                    <div className="grid space-y-2 text-md w-full">
                        <Link onClick={openMenu} to="/profile" className="px-3 py-2 font-medium flex space-x-2"><UserIcon className="block h-6 w-6 opacity-30" aria-hidden="true" /><p>Профиль</p></Link>
                        <Link onClick={openMenu} to="/panel" className="px-3 py-2 font-medium flex space-x-2"><LibraryIcon className="block h-6 w-6 opacity-30" aria-hidden="true" /><p>Управление</p></Link>
                        <Link onClick={openMenu} to="/settings" className="px-3 py-2 font-medium flex space-x-2"><CogIcon className="block h-6 w-6 opacity-30" aria-hidden="true" /><p>Настройки</p></Link>
                    </div>
                </div>
                <div className="flex items-center text-white bg-zinc-900 space-x-4 shadow-md shadow-purple-900">
                    <div className="grid space-y-2 text-md w-full">
                        <Link onClick={openMenu} to="/" className="px-3 py-2 font-medium flex space-x-2"><HomeIcon className="block h-6 w-6 opacity-30" aria-hidden="true" /><p>Главная</p></Link>
                        <Link onClick={openMenu} to="/top" className="px-3 py-2 font-medium flex space-x-2"><PresentationChartBarIcon className="block h-6 w-6 opacity-30" aria-hidden="true" /><p>Рейтинг</p></Link>
                        <Link onClick={openMenu} to="/info" className="px-3 py-2 font-medium flex space-x-2"><InformationCircleIcon className="block h-6 w-6 opacity-30" aria-hidden="true" /><p>Информация</p></Link>
                    </div>
                </div>
                <div className="flex items-center text-white bg-zinc-900 space-x-4 shadow-md shadow-purple-900">
                    <div className="grid space-y-2 text-md w-full">
                        <Link onClick={openMenu} to="/bans" className="px-3 py-2 font-medium flex space-x-2"><BanIcon className="block h-6 w-6 opacity-30" aria-hidden="true" /><p>Бан лист</p></Link>
                        <Link onClick={openMenu} to="/muts" className="px-3 py-2 font-medium flex space-x-2"><VolumeOffIcon className="block h-6 w-6 opacity-30" aria-hidden="true" /><p>Мут лист</p></Link>
                    </div>
                </div>
                <div className="flex items-center text-white bg-zinc-900 space-x-4 shadow-md shadow-purple-900">
                    <div className="grid space-y-2 text-md w-full">
                        <Link onClick={openMenu} to="/exit" className="nav-it px-3 py-2 font-medium flex space-x-2"><LogoutIcon className="block h-6 w-6 opacity-30" aria-hidden="true" /><p>Выход</p></Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Menu;