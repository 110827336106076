import React from 'react';
import Veter from '../rewards/veter.jsx'
import In from '../rewards/in.jsx'

const History = () => {
    return(
        <div className="text-center grid grid-cols-1 ml-2 mr-2 sm:space-x-2 sm:space-y-0 sm:mt-2 mt-0 text-white text-opacity-70 font-sans">
            <div className="text-lg grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 2xl:grid-cols-6 mr-2 space-x-4 space-y-4">
                <Veter />
                <In />
            </div>
        </div>
    );
};
export default History;