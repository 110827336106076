import React from 'react';
import {Link} from "react-router-dom";
import Reward from "./reward";
import moment from 'moment';
import 'moment/locale/ru';
moment.locale('ru')

class Online extends React.Component {

    render() {
        return (
            <div className="col-span-5 grid grid-cols-1 lg:grid-cols-2 text-white sm:space-x-2 space-y-4 lg:space-y-0">
                <div className="rounded-2xl bg-zinc-800 bg-opacity-30">
                    <div className="pt-4 text-lg text-center">Игроков онлайн</div>
                    <div className="mb-2 text-sm text-white text-opacity-50 text-center">Онлайн: {this.props.onlinecount}</div>
                    <div className="text-sm p-4 pt-0 divide-y divide-dashed divide-slate-800 overflow-auto h-72">
                        {
                            this.props.items.map(item => 
                                <div key={item.time} className="flex items-center p-2 text-white space-x-4">
                                    <img src={item.avatar} alt="Аватарка" className="rounded-l-full w-14"/>
                                    <div className="grid">
                                        <Link to={"/profile/"+item.steam} className="font-medium hover:text-purple-500 duration-500 text-white text-opacity-80 mt-auto mb-auto">{item.name}</Link>
                                        <span className="text-white text-opacity-30">{moment.unix(item.time).fromNow()}</span>
                                        <a className="text-purple-500 hover:text-purple-200 duration-500 cursor-pointer" href={item.link} target="_blank" rel="noreferrer">{item.server}🔗</a>
                                    </div>
                                </div>
                            )
                        }
                    </div>
                </div>
                <Reward/>
            </div>
        )
    }
}

export default Online;