import React, { Component } from 'react'

export default class Arena extends Component {
    render() {
        return (
            <div className="bg-zinc-800 bg-opacity-30 rounded-2xl mt-4 ml-2 text-white p-4 text-opacity-70 text-sm">
                <p className="text-lg p-1">Вип:</p>
                <p className="pl-4">- Кик</p>
                <p className="pl-4">- Неоновая подсветка</p>
                <p className="pl-4">- Монетка в табе</p>
                <p className="pl-4">- Электрические эффекты при выстреле</p>
                <p className="pl-4">- Растворение тела</p>
                <p className="text-lg p-1">Вип+:</p>
                <p className="pl-4">- Все что может Вип</p>
                <p className="pl-4">- Выше иммунитет чем у вип</p>
                <p className="text-lg p-1">Админ:</p>
                <p className="pl-4">- Все что может Вип+</p>
                <p className="pl-4">- Перчатки</p>
                <p className="pl-4">- Сообщение при входе на сервер</p>
                <p className="pl-4">- Команда /rs</p>
                <p className="pl-4">- Выбор модельки персонажа</p>
                <p className="pl-4">- Стандартные возможности админа (кик, бан, мут и т.д.)</p>
                <p className="text-lg p-1">Админ+:</p>
                <p className="pl-4">- Все что может Админ</p>
                <p className="pl-4">- Выше иммунитет чем у админа</p>
                <p className="text-lg p-1">Админ++:</p>
                <p className="pl-4">- Все что может Админ+</p>
                <p className="pl-4">- Выше иммунитет чем у Админ+</p>
                <p className="text-lg p-1">Hydra:</p>
                <p className="pl-4">- Все что может Админ++</p>
                <p className="pl-4">- Отображение наносимого урона</p>
                <p className="pl-4">- Выше иммунитет чем у Админ++</p>
                <p className="text-lg p-1">Hydra+:</p>
                <p className="pl-4">- Все что может Hydra</p>
                <p className="pl-4">- Выше иммунитет чем у Hydra</p>
                <p className="text-lg p-1">Hydra++:</p>
                <p className="pl-4">- Все что может Hydra+</p>
                <p className="pl-4">- Выше иммунитет чем у Hydra+</p>
                <p className="text-lg p-1">Бог:</p>
                <p className="pl-4">- Все что может Hydra++</p>
                <p className="pl-4">- Выше иммунитет чем у Hydra++</p>
            </div>
        )
    }
}
