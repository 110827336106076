import React from 'react';
import {Tab, TabList, TabPanel, Tabs} from "react-tabs";
import Statsrv from './statsrv'

const Stat = () => {
    return (
        <div className="col-span-1 md:col-span-2 lg:col-span-4 text-center grid grid-cols-1 md:grid-cols-2 lg:grid-cols-8 sm:space-x-2 space-y-2">
            <div className="row-span-1 col-span-1 md:col-span-2 lg:col-span-8">
                <Tabs className="" selectedTabClassName="bg-purple-700 bg-opacity-50">
                    <TabList className="grid sm:grid-cols-3 text-center sm:pl-0 sm:pr-0 pl-2 pr-2 space-y-2 sm:space-y-0 sm:space-x-2 sm:ml-2">
                        <Tab className="rounded-lg border border-purple-800 border-opacity-50 shadow-sm shadow-purple-800 text-md text-white text-slate-400 hover:bg-purple-700 hover:bg-opacity-50 duration-500 cursor-pointer">
                            <div>Арена 1vs1</div>
                        </Tab>
                        <Tab className="rounded-lg border border-purple-800 border-opacity-50 shadow-sm shadow-purple-800 text-md text-white text-slate-400 hover:bg-purple-700 hover:bg-opacity-50 duration-500 cursor-pointer">
                            <div>Снайперы Lego</div>
                        </Tab>
                        <Tab className="rounded-lg border border-purple-800 border-opacity-50 shadow-sm shadow-purple-800 text-md text-white text-slate-400 hover:bg-purple-700 hover:bg-opacity-50 duration-500 cursor-pointer">
                            <div>Mirage Only</div>
                        </Tab>
                    </TabList>
                    <div className="col-span-2 lg:col-span-4 ">
                        <TabPanel>
                            <Statsrv />
                        </TabPanel>
                        <TabPanel>
                            <Statsrv />
                        </TabPanel>
                        <TabPanel>
                            <Statsrv />
                        </TabPanel>
                    </div>
                </Tabs>
            </div>
        </div>
    );
};

export default Stat;