import React from 'react';

const In = () => {
    return(
        <div className="pb-2 ml-4 mt-4 rounded-2xl border border-opacity-50 border-purple-800 shadow-md shadow-purple-700 bg-zinc-800 bg-opacity-30">
            <div className="bg-zinc-900 rounded-t-2xl">ЭКСКЛЮЗИВ</div>
            <div className="w-32 h-32 ml-auto mr-auto mt-4 animate-spin" title="Получить этот символ может только настоящий воин осилив все трудности испытаний..."><img src="/img/rewards/in.png" alt=""/></div>
            <div className="mt-3 text-opacity-100 text-white animate-pulse">Инь-Янь</div>
            <div className="mt-3">FearStrike</div>
            <div>2022 года</div>
        </div>
    );
};
export default In;