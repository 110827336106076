import React from 'react';
import {Link} from "react-router-dom";
import Online from "./online";

class Userpost extends React.Component {

    render() {
        if (this.props.error) {
            return <div>Ошибка: {this.props.error.message}</div>;
        } else if (!this.props.isLoaded) {    
            return (
                <div className="col-span-5 space-y-4">
                    <div className="bg-zinc-800 bg-opacity-30 rounded-2xl grid grid-cols-1 xl:grid-cols-2">
                        <div className="mt-auto mb-auto text-left text-white">
                            <p className="p-3 text-white text-opacity-80 text-sm h-56">
                                Загрузка...
                            </p>
                            <div className="p-4 flex">
                                <Link to="/profile" className="font-medium hover:text-purple-500 duration-500 text-white text-opacity-80"><p>Загрузка...</p></Link>
                                <p className="ml-4 text-white text-opacity-40">00.00.0000</p>
                            </div>
                        </div>
                        <div>
                            <div className="xl:h-56">
                                <img src="img/loading.gif" className="xl:rounded-tr-2xl xl:h-56 ml-auto" alt=""/>
                            </div>
                            <div className="mt-3 mb-4 xl:mb-0 ml-auto mr-auto xl:mr-2 bg-purple-800 hover:bg-purple-700 hover:shadow-purple-700 hover:shadow-md duration-500 cursor-pointer w-40 text-sm text-center text-white text-opacity-80 rounded-md p-1">Сменить пост за 5₽</div>
                        </div>
                    </div>
                </div>
            );
        } else {    
            let usrpostimg = "img/bg.jpg"
            if (this.props.items.userpost.img) {
                usrpostimg = this.props.items.userpost.img
            }
            return (
                <div className="col-span-5 space-y-4">
                    <div className="bg-zinc-800 bg-opacity-30 rounded-2xl">
                        <div className="grid grid-cols-1 xl:grid-cols-2">
                            <div className="mt-auto mb-auto text-left text-white">
                                <p className="pt-3 text-center text-white text-opacity-90 text-l">
                                    {this.props.items.userpost.head}
                                </p>
                                <p className="p-3 text-white text-opacity-80 text-sm h-56">
                                    {this.props.items.userpost.text}
                                </p>
                            </div>
                            <div>
                                <div className="xl:h-56">
                                    <img src={usrpostimg} className="xl:rounded-tr-2xl xl:h-56 ml-auto" alt=""/>
                                </div>
                            </div>
                        </div>
                        <div className="p-4 flex row">
                            <Link to={"/profile/"+this.props.items.userpost.author} className="font-medium hover:text-purple-500 duration-500 text-white text-opacity-80 mt-auto mb-auto"><p>{this.props.items.userpost.name}</p></Link>
                            <p className="ml-4 mt-auto mb-auto text-white text-opacity-40">{this.props.items.userpost.times}</p>
                            <Link to="/panel" className="mt-auto mb-auto xl:mb-0 ml-auto mr-auto xl:mr-2 bg-purple-800 hover:bg-purple-700 hover:shadow-purple-700 hover:shadow-md duration-500 cursor-pointer w-40 text-sm text-center text-white text-opacity-80 rounded-md p-1">Сменить пост за 5₽</Link>
                        </div>
                    </div>
                    <Online error={this.props.error} isLoaded={this.props.isLoaded} items={this.props.items.online} onlinecount={this.props.items.onlinecount}/>
                </div>
            );
        }
    }
}

export default Userpost;