import React, { Component } from 'react'

export default class Error extends Component {
  render() {
    return (
        <div className="container mx-auto bg-zinc-800 bg-opacity-30 rounded-2xl text-white p-4 text-opacity-70 text-center text-2xl">
          Страница не найдена!
        </div>
    )
  }
}
