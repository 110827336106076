import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Binds from './binds';
import Main from './main';
import Priv from './priv';
import Rules from './rules';

const Info = () => {
    return (
        <Tabs className="container mx-auto rounded-3xl rounded-t-2xl grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 sm:space-x-4" selectedTabClassName="bg-purple-700 bg-opacity-50">
            <TabList className="col-span-1 text-center space-y-3 m-2 mb-4">
                <Tab className="rounded-lg border border-purple-800 border-opacity-50 shadow-sm shadow-purple-800 text-lg text-white text-slate-400 hover:bg-purple-700 hover:bg-opacity-50 duration-500 cursor-pointer">
                    <div>Правила</div>
                </Tab>
                <Tab className="rounded-lg border border-purple-800 border-opacity-50 shadow-sm shadow-purple-800 text-lg text-white text-slate-400 hover:bg-purple-700 hover:bg-opacity-50 duration-500 cursor-pointer">
                    <div>Общее</div>
                </Tab>
                <Tab className="rounded-lg border border-purple-800 border-opacity-50 shadow-sm shadow-purple-800 text-lg text-white text-slate-400 hover:bg-purple-700 hover:bg-opacity-50 duration-500 cursor-pointer">
                    <div>Бинды</div>
                </Tab>
                <Tab className="rounded-lg border border-purple-800 border-opacity-50 shadow-sm shadow-purple-800 text-lg text-white text-slate-400 hover:bg-purple-700 hover:bg-opacity-50 duration-500 cursor-pointer">
                    <div>Привилегии</div>
                </Tab>
            </TabList>
            <div className="col-span-2 lg:col-span-4 mt-2">
                <TabPanel>
                    <Rules />
                </TabPanel>
                <TabPanel>
                    <Main />
                </TabPanel>
                <TabPanel>
                    <Binds />
                </TabPanel>
                <TabPanel>
                    <Priv />
                </TabPanel>
            </div>
        </Tabs>
    );
};

export default Info;