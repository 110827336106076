import React from 'react';

const Reward = () => {
    return (
        <div className="space-y-2 row-span-4 mt-4 grad rounded-2xl">
            <div className="w-full text-white text-md text-opacity-60">
                <div className="p-4 pb-1 text-white text-center text-2xl">Получай бонусы бесплатно!</div>
                <div className="pl-4 pr-4 text-white text-opacity-60 text-md">Выполни эти задания и каждую неделю получай 50 бонусов!</div>
                <div className="pl-4 pr-4 pt-2">
                    <p><strike>1. Войти на сайт</strike></p>
                    <p><strike>2. Привязать свой VK</strike></p>
                    <p>3. <a href="https://vk.com/fearstrike" target="_blank" rel="noreferrer" className="hover:text-purple-400 duration-500">Вступить</a> в группу VK</p>
                </div>
                <div className="mt-4 ml-auto mr-auto bg-purple-800 hover:bg-purple-700 hover:shadow-purple-700 hover:shadow-md duration-500 cursor-pointer w-32 text-sm text-center text-white text-opacity-80 rounded-md p-1">Получить 50Б</div>
                <div className="pl-4 pr-4 pt-2 pb-2 text-center">
                    <p>35 человек получило награду</p>
                    <p>Следующая награда через 2 дня</p>
                </div>
            </div>
        </div>
    );
};

export default Reward;