import React, { Component } from 'react'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import Admins from './admins';
import Cheats from './cheats';
import Players from './players';

export default class Rules extends Component {
    render() {
        return (
            <Tabs className="" selectedTabClassName="bg-purple-700 bg-opacity-50">
                <TabList className="grid sm:grid-cols-3 text-center sm:pl-0 sm:pr-0 pl-2 pr-2 space-y-2 sm:space-y-0 sm:space-x-2 sm:ml-2">
                    <Tab className="rounded-lg border border-purple-800 border-opacity-50 shadow-sm shadow-purple-800 text-md text-white text-slate-400 hover:bg-purple-700 hover:bg-opacity-50 duration-500 cursor-pointer">
                        <div>Игрокам</div>
                    </Tab>
                    <Tab className="rounded-lg border border-purple-800 border-opacity-50 shadow-sm shadow-purple-800 text-md text-white text-slate-400 hover:bg-purple-700 hover:bg-opacity-50 duration-500 cursor-pointer">
                        <div>VIP и выше</div>
                    </Tab>
                    <Tab className="rounded-lg border border-purple-800 border-opacity-50 shadow-sm shadow-purple-800 text-md text-white text-slate-400 hover:bg-purple-700 hover:bg-opacity-50 duration-500 cursor-pointer">
                        <div>Проверка на читы</div>
                    </Tab>
                </TabList>
                <div className="col-span-2 lg:col-span-4 ">
                    <TabPanel>
                        <Players />
                    </TabPanel>
                    <TabPanel>
                        <Admins />
                    </TabPanel>
                    <TabPanel>
                        <Cheats />
                    </TabPanel>
                </div>
            </Tabs>
        )
    }
}
