import React from 'react';

class Monitoring extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
          copiedElementId: null,
        };
        this.handleCopyIP = this.handleCopyIP.bind(this);
      }
    
      handleCopyIP(ip, elementId) {
        const inp = document.createElement('input');
        inp.value = `connect ${ip}`;
        document.body.appendChild(inp);
        inp.select();
    
        if (document.execCommand('copy')) {
          this.setState({ copiedElementId: elementId });
          setTimeout(() => this.setState({ copiedElementId: null }), 1500);
        }
    
        document.body.removeChild(inp);
      };

    render() {
        const { error, isLoaded, items } = this.props;
        const { copiedElementId } = this.state;
        if (error) {
            return <div>Ошибка: {error.message}</div>;
        } else if (!isLoaded) {
            return (
                <div className="col-span-3 space-y-4 row-span-4">
                    <div className="col-span-3 space-y-4 row-span-4 mt-4">
                        <div key="1" className="grid grid-cols-2 sm:pb-4 xl:pb-0 sm:grid-cols-1 sm:row xl:grid-cols-2 w-full rounded-2xl text-white text-md text-opacity-60 bg-zinc-800 bg-opacity-30">
                            <div className="w-32 h-28 block sm:hidden xl:block">
                                <img className="rounded-l-2xl w-32 h-28" src="img/loading.gif" alt="234"/>
                            </div>
                            <div className="-ml-8 sm:ml-auto sm:text-center xl:text-left xl:-ml-8 2xl:-ml-12 pt-4">
                                <div className="">Загрузка...</div>
                                <div className="grid grid-cols-2 text-sm">
                                    <div className="pl-0 sm:pl-4 xl:pl-0">Загрузка...</div>
                                    <div className="pr-0 text-left sm:text-right sm:pr-4 xl:text-left xl:pr-0">.../...</div>
                                </div>
                                <div className="grid grid-cols-2 text-sm mt-1 -ml-3 sm:ml-auto lg:ml-8 xl:-ml-3">
                                    <div className="ml-2 pl-2 pr-12 mr-auto w-44 bg-zinc-800 hover:bg-opacity-50 duration-500 rounded-l-2xl p-1">Загрузка...</div>
                                    <div className="ml-7 bg-purple-800 hover:bg-purple-700 hover:shadow-purple-700 hover:shadow-md duration-500 cursor-pointer w-16 rounded-r-2xl p-1 text-center">Играть</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
              );
          } else {
            return (
                <div className="col-span-3 space-y-4 row-span-4">
                  <div className="col-span-3 space-y-4 row-span-4 mt-4">
                    {items.map(item =>
                      <div key={item.id} className="grid grid-cols-2 sm:pb-4 xl:pb-0 sm:grid-cols-1 sm:row xl:grid-cols-2 w-full rounded-2xl text-white text-md text-opacity-60 bg-zinc-800 bg-opacity-30">
                        <div className="w-32 h-28 block sm:hidden xl:block">
                          <img className="rounded-l-2xl w-32" src={item.imap} alt="234"/>
                        </div>
                        <div className="-ml-8 sm:ml-auto sm:text-center xl:text-left xl:-ml-8 2xl:-ml-12 pt-3">
                          <div className="">{item.name}</div>
                          <div className="grid grid-cols-2 text-sm pt-1">
                            <div className="pl-0 sm:pl-4 xl:pl-0">{item.status == 1 ? item.smap : 'Оффлайн'}</div>
                            <div className="pr-0 text-left sm:text-right sm:pr-4 xl:text-left xl:pr-0">{item.status == 1 ? item.numplayers+'/'+item.maxplayers : '0/0'}</div>
                          </div>
                          <div className="grid grid-cols-2 text-sm mt-1 -ml-3 sm:ml-auto lg:ml-8 xl:-ml-3 pt-1">
                            <div id={item.id} className="ml-2 pl-2 pr-12 mr-auto w-44 bg-zinc-800 hover:bg-opacity-50 duration-500 rounded-l-2xl p-1 cursor-pointer" onClick={() => this.handleCopyIP(`${item.ip}:${item.port}`, item.id)}>
                              {copiedElementId === item.id ? 'IP Скопирован!' : `${item.ip}:${item.port}`}
                            </div>
                            <div className="ml-7 bg-purple-800 hover:bg-purple-700 hover:shadow-purple-700 hover:shadow-md duration-500 cursor-pointer w-16 rounded-r-2xl p-1 text-center"><a href={item.joinlink} rel="noreferrer">Играть</a></div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
          }
    }
  }

export default Monitoring;