import React from 'react';
import {Link} from "react-router-dom";


class Usertop extends React.Component {
  
    render() {
      if (this.props.error) {
        return <div>Ошибка: {this.props.error.message}</div>;
      } else if (!this.props.isLoaded) {
          return (
              <div className="col-span-3 space-y-2 row-span-4 mt-4">
                <div className="pb-2 w-full text-white text-md text-opacity-60 bg-zinc-800 bg-opacity-30 rounded-2xl">
                    <div className="p-4 pb-0 text-white text-center text-xl">Топ 3 за прошлый месяц</div>
                        <div className="pb-2 text-center border-b border-gray-800">
                            <div className="mt-2">Загрузка...</div>
                        </div>
                </div>
              </div>
          );
      } else {
        let i = 0
        let ii = 0
        return (
            <div className="col-span-3 space-y-4 row-span-4 mt-4">
              <div className="pb-2 w-full text-white text-md text-opacity-60 bg-zinc-800 bg-opacity-30 rounded-2xl">
                  <div className="p-4 pb-0 text-white text-center text-xl">Топ 3 за прошлый месяц</div>
                  {
                      this.props.items.map(item =>
                          <div key={i++} className="pb-2 text-center border-b border-gray-800">
                              
                              <div className="mt-2">{item.sname}</div>
                              <table className="mt-2 w-full">
                                  <tbody>
                                      {
                                          item.players.map(player =>
                                              <tr key={ii++} className="h-7">
                                                  <td className="w-1/12 text-yellow-500"><img className="w-6 h-6 ml-2 text-yellow-500" src={player.cup} alt="Изображение"/></td>
                                                  <td className="w-3/12"><img className="ml-auto w-4/5" src={player.rank} alt="Изображение"/></td>
                                                  <td className="w-6/12"><Link to={player.steam} className="hover:text-purple-500 duration-500 text-white text-opacity-80">{player.name}</Link></td>
                                                  <td className="w-2/12 text-left">{player.value}</td>
                                              </tr>
                                          )
                                      }
                                  </tbody>
                              </table>
                          </div>
                      )
                  }
              </div>
            </div>
        );
      }
    }
};

export default Usertop;