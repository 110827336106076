import React, {useState} from 'react';
import Index from './pages/main'
import Menu from './pages/menu'
import Nav from './components/navbar'
import Footer from './components/footer'
import {BrowserRouter, Routes, Route} from 'react-router-dom'
import Profile from "./pages/profile";
import Info from './pages/info';
import Error from './pages/error';
function App() {
  const [menuActive, setMenuActive] = useState(false)

  const openMenu = () => {
    setMenuActive(!menuActive)
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  return (
    <BrowserRouter>
    <Nav openMenu={openMenu}/>
      <div className="sm:mt-24">
        <div className={menuActive ? 'h-full menus active' : 'h-full menus'}>
          <Menu openMenu={openMenu}/>
        </div>
        <div className={menuActive ? 'cont active' : 'cont'}>
          <Routes>
            <Route path="/" element={<Index />} />
            <Route path="/info" element={<Info />} />
            <Route path="/profile" element={<Profile />} />
            <Route path='*' element={<Error />} />
          </Routes>
        </div>
      </div>

      <Footer/>
    </BrowserRouter>
  );
}

export default App;
