import React from 'react';

const History = () => {
    return(
        <div className="text-center grid grid-cols-1 sm:grid-cols-2 ml-2 mr-2 space-y-4 sm:space-x-2 sm:space-y-0 sm:mt-2 mt-0 text-white text-opacity-70 font-sans">
            <div className="rounded-2xl border-l border-t border-r border-purple-800 border-opacity-50 bg-zinc-800 bg-opacity-30">
                <div className="p-1 text-lg">История транзакций</div>
                <div className=" max-h-56 overflow-auto">
                    <table className="w-full text-sm">
                        <thead>
                        <tr className="bg-purple-800 rounded bg-opacity-50">
                            <th className="w-1/12">#</th>
                            <th className="w-3/12">Когда</th>
                            <th className="w-2/12">Бонусов</th>
                            <th className="w-2/12">Сумма</th>
                            <th className="w-4/12">Статус</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr className="h-7">
                            <td className="ml-auto mr-auto">1</td>
                            <td className="">04.12.2020 16:31</td>
                            <td className="">100</td>
                            <td className="">20р</td>
                            <td className="">Ожидание платежа</td>
                        </tr>
                        <tr className="h-7">
                            <td className="ml-auto mr-auto">2</td>
                            <td className="">04.12.2020 14:48</td>
                            <td className="">1200</td>
                            <td className="">240р</td>
                            <td className="">Успех</td>
                        </tr>
                        <tr className="h-7">
                            <td className="ml-auto mr-auto">3</td>
                            <td className="">04.12.2020 16:31</td>
                            <td className="">100</td>
                            <td className="">20р</td>
                            <td className="">Ожидание платежа</td>
                        </tr>
                        <tr className="h-7">
                            <td className="ml-auto mr-auto">4</td>
                            <td className="">04.12.2020 16:31</td>
                            <td className="">100</td>
                            <td className="">20р</td>
                            <td className="">Ожидание платежа</td>
                        </tr>
                        <tr className="h-7">
                            <td className="ml-auto mr-auto">5</td>
                            <td className="">04.12.2020 16:31</td>
                            <td className="">100</td>
                            <td className="">20р</td>
                            <td className="">Ожидание платежа</td>
                        </tr>
                        <tr className="h-7">
                            <td className="ml-auto mr-auto">6</td>
                            <td className="">04.12.2020 16:31</td>
                            <td className="">100</td>
                            <td className="">20р</td>
                            <td className="">Ожидание платежа</td>
                        </tr>
                        <tr className="h-7">
                            <td className="ml-auto mr-auto">7</td>
                            <td className="">04.12.2020 16:31</td>
                            <td className="">100</td>
                            <td className="">20р</td>
                            <td className="">Ожидание платежа</td>
                        </tr>
                        <tr className="h-7">
                            <td className="ml-auto mr-auto">8</td>
                            <td className="">04.12.2020 16:31</td>
                            <td className="">100</td>
                            <td className="">20р</td>
                            <td className="">Ожидание платежа</td>
                        </tr>
                        <tr className="h-7">
                            <td className="ml-auto mr-auto">9</td>
                            <td className="">04.12.2020 16:31</td>
                            <td className="">100</td>
                            <td className="">20р</td>
                            <td className="">Ожидание платежа</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};
export default History;