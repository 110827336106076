import React from 'react';

const Account = () => {
    return(
        <div className="col-span-1 md:col-span-2 lg:col-span-4 text-center grid grid-cols-2 md:grid-cols-2 lg:grid-cols-5 space-x-2 space-y-2 mr-2">
            <div className="rounded-lg border border-purple-800 shadow-sm shadow-purple-800 text-sm text-white text-slate-400 mt-2 md:ml-2 p-2 ml-2 hover:bg-purple-600 hover:bg-opacity-5">
                <div>SteamID - 32 формат</div>
                <div>STEAM_1:1:623415871</div>
            </div>
            <div className="rounded-lg border border-purple-800 shadow-sm shadow-purple-800 text-sm text-white text-slate-400 p-2 hover:bg-purple-600 hover:bg-opacity-5">
                <div>SteamID - 64 формат</div>
                <div>76561199207097471</div>
            </div>
            <div className="rounded-lg border border-purple-800 shadow-sm shadow-purple-800 text-sm text-white text-slate-400 p-2 hover:bg-purple-600 hover:bg-opacity-5">
                <div>Аккаунт создан</div>
                <div>12.09.2021 в 16:18</div>
            </div>
            <div className="rounded-lg border border-purple-800 shadow-sm shadow-purple-800 text-sm text-white text-slate-400 p-2 hover:bg-purple-600 hover:bg-opacity-5">
                <div>Prime статус</div>
                <div>Активен</div>
            </div>
            <div className="rounded-lg border border-purple-800 shadow-sm shadow-purple-800 text-sm text-white text-slate-400 p-2 hover:bg-purple-600 hover:bg-opacity-5">
                <div>Зареган на сайте</div>
                <div>12.09.2021</div>
            </div>
            <div className="rounded-lg border border-purple-800 shadow-sm shadow-purple-800 text-sm text-white text-slate-400 p-2 hover:bg-purple-600 hover:bg-opacity-5">
                <div>Полученных банов</div>
                <div>2</div>
            </div>
            <div className="rounded-lg border border-purple-800 shadow-sm shadow-purple-800 text-sm text-white text-slate-400 p-2 hover:bg-purple-600 hover:bg-opacity-5">
                <div>VAC cтатус</div>
                <div>1 блокировка</div>
            </div>
            <div className="rounded-lg border border-purple-800 shadow-sm shadow-purple-800 text-sm text-white text-slate-400 p-2 hover:bg-purple-600 hover:bg-opacity-5">
                <div>Состоит в беседе</div>
                <div>Нет</div>
            </div>
            <div className="rounded-lg border border-purple-800 shadow-sm shadow-purple-800 text-sm text-white text-slate-400 p-2 hover:bg-purple-600 hover:bg-opacity-5">
                <div>Подписан на группу</div>
                <div>Да</div>
            </div>
            <div className="rounded-lg border border-purple-800 shadow-sm shadow-purple-800 text-sm text-white text-slate-400 p-2 hover:bg-purple-600 hover:bg-opacity-5">
                <div>Статус</div>
                <div>Не ограничен</div>
            </div>
            <div className="rounded-lg border border-purple-800 shadow-sm shadow-purple-800 text-sm text-white text-slate-400 p-2 hover:bg-purple-600 hover:bg-opacity-5">
                <div>Часов на проекте</div>
                <div>400</div>
            </div>
            <div className="rounded-lg border border-purple-800 shadow-sm shadow-purple-800 text-sm text-white text-slate-400 p-2 hover:bg-purple-600 hover:bg-opacity-5">
                <div>Брак</div>
                <div>_Temp</div>
            </div>
            <div className="rounded-lg border border-purple-800 shadow-sm shadow-purple-800 text-md text-white text-slate-400 p-2 hover:bg-purple-600 hover:bg-opacity-5">
                <div>Реферальный код</div>
                <div>Kud</div>
            </div>
            <div className="rounded-lg border border-purple-800 shadow-sm shadow-purple-800 text-sm text-white text-slate-400 p-2 hover:bg-purple-600 hover:bg-opacity-5">
                <div>Реферал</div>
                <div>Kudesnik</div>
            </div>
            <div className="rounded-lg border border-purple-800 shadow-sm shadow-purple-800 text-sm text-white text-slate-400 p-2 hover:bg-purple-600 hover:bg-opacity-5">
                <div>Привилегия</div>
                <div>Создатель</div>
            </div>
        </div>
    );
};
export default Account;