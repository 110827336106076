import React, { Component } from 'react'

export default class Cheats extends Component {
    render() {
        return (
            <div className="bg-zinc-800 bg-opacity-30 rounded-2xl mt-4 ml-2 text-white p-4 text-opacity-70 text-sm">
                <p className="bg-red-800 bg-opacity-40 rounded-lg p-2 mb-2">За нарушения данных правил по проверке на стороннее ПО, администратор может понести наказание.</p>
                <p className="p-1">1. Во время проверки администрация обязуется вести диалог с уважением к проверяемому.</p>
                <p className="p-1">2. Проверка проводится исключительно в том случае, если игрок вызывал подозрения.</p>
                <p className="p-1">3. Во время проверки игрок вправе отказать от демонстрации каких-либо ресурсов, если он посчитает это нужным. Примечание: отказ может повлиять на итоговое решение администрации.</p>
                <p className="p-1">4. Игрок вправе отказаться / остановить проверку в любой момент, если он посчитает это нужным. Примечание: отказ может повлиять на итоговое решение администрации.</p>
                <p className="p-1">5. Администратор не имеет право запрашивать / просматривать личные переписки, либо файлы без Вашего согласия. Примечание: диалоги от сообществ по распространению стороннего ПО в различных соц. сетях - не является личной перепиской.</p>
                <p className="p-1">6. Проверка ресурсов происходит на добровольной основе, администрация не в праве заставлять делать какие-либо действия.</p>
                <p className="p-1">7. Администрации сервера запрещается разглашать информацию пользователя полученную в ходе проверки лицам не являющимися действующими администраторами.</p>
                <p className="p-1">8. В случае нахождения посторонних файлов у проверяемого, администрация вправе выдать блокировку аккаунта и завершить проверку.</p>
                <p className="p-1">9. Если в ходе проверки были выявлены другие нарушения, администрация вправе выдать соответствующие наказание.</p>
                <p className="p-1">10. Проверка на сторонне ПО не исключает факт наличия / использования стороннего ПО. В случае, если в ходе проверки не было выявлено нарушение, то игрок всё равно может получить игровую блокировки.</p>
                <p className="p-1">11.1 Администрация проекта не в праве требовать Вас скачать какие-либо файлы. Исключение: ExecutedProgramsList, LastActivityView, USBDeview, Everything, Shellbag Analyzer. Примечание: скачивать необходимо только с верифицированных сайтов, например: www.nirsoft.net, www.voidtools.com, softportal.com</p>
                <p className="p-1">11.2 Любые файлы, которые относятся к исключению, игрок может проверить на вирусы, вредоносное ПО и прочее, на любом удобном ему сайте. Пояснение: администратор не вправе как-либо препятствовать или выбирать сайт для проверки, всё это делается на личное усмотрение игрока.</p>
            </div>
        )
    }
}
