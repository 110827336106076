import React from 'react';

const Statsrv = () => {
    return (
        <div className="col-span-1 md:col-span-2 lg:col-span-4 text-center grid grid-cols-1 md:grid-cols-2 lg:grid-cols-8 sm:space-x-2 space-y-2">
            <div className="rounded-lg text-lg text-white text-slate-400 mt-2 md:ml-2 row-span-3 col-span-4 md:col-span-2">
                <div className="border border-purple-800 border-opacity-50 bg-zinc-800 bg-opacity-30 rounded-lg rounded-b-none">
                    <div className="grid grid-cols-3 items-center list-none mt-4 max-h-8 px-4">
                        <li className="ml-auto mr-auto">
                            <img src="/img/icons/weapons/awp.svg" alt="" className="max-h-8 opacity-70"/>
                        </li>
                        <li className="ml-auto mr-auto">
                            <img src="/img/icons/weapons/deagle.svg" alt="" className="max-h-8 opacity-70"/>
                        </li>
                        <li className="ml-auto mr-auto">
                            <img src="/img/icons/weapons/knifet.svg" alt="" className="max-h-8 opacity-70"/>
                        </li>
                    </div>
                    <div className="grid grid-cols-3 list-none">
                        <li className="ml-auto mr-auto">
                            324
                        </li>
                        <li className="ml-auto mr-auto">
                            110
                        </li>
                        <li className="ml-auto mr-auto">
                            43
                        </li>
                    </div>
                </div>
                <div className="text-sm overflow-auto max-h-56 bg-zinc-800 bg-opacity-30 rounded-b-xl">
                    <table className="w-full">
                        <thead>
                        <tr className="bg-purple-800 bg-opacity-50">
                            <th className=""></th>
                            <th className="">ОРУЖИЕ</th>
                            <th className="">УБИЙСТВА</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr className="h-7">
                            <td className="ml-auto mr-auto"><img src="/img/icons/weapons/glock.svg" alt="" className="max-h-4 ml-auto mr-auto opacity-50"/></td>
                            <td className="">GLOCK</td>
                            <td className="">23 kills</td>
                        </tr>
                        <tr className="h-7">
                            <td className="ml-auto mr-auto"><img src="/img/icons/weapons/ak47.svg" alt="" className="max-h-4 ml-auto mr-auto opacity-50"/></td>
                            <td className="">AK-47</td>
                            <td className="">231 kills</td>
                        </tr>
                        <tr className="h-7">
                            <td className="ml-auto mr-auto"><img src="/img/icons/weapons/usp.svg" alt="" className="max-h-4 ml-auto mr-auto opacity-50"/></td>
                            <td className="">USP SILENCER</td>
                            <td className="">23 kills</td>
                        </tr>
                        <tr className="h-7">
                            <td className="ml-auto mr-auto"><img src="/img/icons/weapons/revolver.svg" alt="" className="max-h-4 ml-auto mr-auto opacity-50"/></td>
                            <td className="">REVOLVER</td>
                            <td className="">23 kills</td>
                        </tr>
                        <tr className="h-7">
                            <td className="ml-auto mr-auto"><img src="/img/icons/weapons/autoshotgun.svg" alt="" className="max-h-4 ml-auto mr-auto opacity-50"/></td>
                            <td className="">XM1014</td>
                            <td className="">23 kills</td>
                        </tr>
                        <tr className="h-7">
                            <td className="ml-auto mr-auto"><img src="/img/icons/weapons/ssg.svg" alt="" className="max-h-4 ml-auto mr-auto opacity-50"/></td>
                            <td className="">SSG08</td>
                            <td className="">23 kills</td>
                        </tr>
                        <tr className="h-7">
                            <td className="ml-auto mr-auto"><img src="/img/icons/weapons/zeus.svg" alt="" className="max-h-4 ml-auto mr-auto opacity-50"/></td>
                            <td className="">ZEUS</td>
                            <td className="">23 kills</td>
                        </tr>
                        <tr className="h-7">
                            <td className="ml-auto mr-auto"><img src="/img/icons/weapons/zeus.svg" alt="" className="max-h-4 ml-auto mr-auto opacity-50"/></td>
                            <td className="">ZEUS</td>
                            <td className="">23 kills</td>
                        </tr>
                        <tr className="h-7">
                            <td className="ml-auto mr-auto"><img src="/img/icons/weapons/zeus.svg" alt="" className="max-h-4 ml-auto mr-auto opacity-50"/></td>
                            <td className="">ZEUS</td>
                            <td className="">23 kills</td>
                        </tr>
                        <tr className="h-7">
                            <td className="ml-auto mr-auto"><img src="/img/icons/weapons/zeus.svg" alt="" className="max-h-4 ml-auto mr-auto opacity-50"/></td>
                            <td className="">ZEUS</td>
                            <td className="">23 kills</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="border-t border-l border-r border-purple-800 bg-opacity-30 rounded-lg border-opacity-50 bg-zinc-800 text-sm text-white text-slate-400 grid grid-cols-2 col-span-4 md:col-span-3 p-2">
                <div className="text-left">Убийства:</div>
                <div className="text-right">419</div>
                <div className="text-left">Смертей:</div>
                <div className="text-right">419</div>
                <div className="text-left">Ассистов:</div>
                <div className="text-right">419</div>
                <div className="text-left">Убийств в голову:</div>
                <div className="text-right">193 ( 46.1% )</div>
                <div className="text-left">Убийств / Смертей - К/Д:</div>
                <div className="text-right">1.37 ( 419 / 305 )</div>
                <div className="text-left">Попаданий / Выстрелов:</div>
                <div className="text-right">21.3% ( 884 / 4153 )</div>
            </div>
            <div className="border-t border-l border-opacity-50 border-r border-purple-800 rounded-lg bg-zinc-800 bg-opacity-30 text-sm text-white text-slate-400 row-span-2 max-h-72 overflow-auto col-span-4 md:col-span-3 p-2">
                <table className="w-full">
                    <thead>
                    <tr>
                        <th className="w-1/6">МЕСТО</th>
                        <th className="w-2/5">ИГРОК</th>
                        <th className="">ОЧКИ</th>
                        <th className="w-1/5">ЗВАНИЕ</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr className="">
                        <td className="ml-auto mr-auto">213</td>
                        <td className="">Ded</td>
                        <td className="">1247</td>
                        <td className="ml-auto mr-auto"><img src="/img/icons/ranks/20.png" alt="" className="max-h-8 ml-auto mr-auto"/></td>
                    </tr>
                    <tr className="">
                        <td className="ml-auto mr-auto">214</td>
                        <td className="">Ded</td>
                        <td className="">1247</td>
                        <td className="ml-auto mr-auto"><img src="/img/icons/ranks/18.png" alt="" className="max-h-8 ml-auto mr-auto"/></td>
                    </tr>
                    <tr className="">
                        <td className="ml-auto mr-auto">215</td>
                        <td className="">Ded</td>
                        <td className="">1247</td>
                        <td className="ml-auto mr-auto"><img src="/img/icons/ranks/16.png" alt="" className="max-h-8 ml-auto mr-auto"/></td>
                    </tr>
                    <tr className="">
                        <td className="ml-auto mr-auto">216</td>
                        <td className="">Ded</td>
                        <td className="">1247</td>
                        <td className="ml-auto mr-auto"><img src="/img/icons/ranks/15.png" alt="" className="max-h-8 ml-auto mr-auto"/></td>
                    </tr>
                    <tr className="">
                        <td className="ml-auto mr-auto">217</td>
                        <td className="">Ded</td>
                        <td className="">1247</td>
                        <td className="ml-auto mr-auto"><img src="/img/icons/ranks/14.png" alt="" className="max-h-8 ml-auto mr-auto"/></td>
                    </tr>
                    <tr className="bg-purple-700 bg-opacity-50">
                        <td className="ml-auto mr-auto">218</td>
                        <td className="">Ded</td>
                        <td className="">1247</td>
                        <td className="ml-auto mr-auto"><img src="/img/icons/ranks/12.png" alt="" className="max-h-8 ml-auto mr-auto"/></td>
                    </tr>
                    <tr className="">
                        <td className="ml-auto mr-auto">219</td>
                        <td className="">Ded</td>
                        <td className="">1247</td>
                        <td className="ml-auto mr-auto"><img src="/img/icons/ranks/8.png" alt="" className="max-h-8 ml-auto mr-auto"/></td>
                    </tr>
                    <tr className="">
                        <td className="ml-auto mr-auto">220</td>
                        <td className="">Ded</td>
                        <td className="">1247</td>
                        <td className="ml-auto mr-auto"><img src="/img/icons/ranks/4.png" alt="" className="max-h-8 ml-auto mr-auto"/></td>
                    </tr>
                    <tr className="">
                        <td className="ml-auto mr-auto">221</td>
                        <td className="">Ded</td>
                        <td className="">1247</td>
                        <td className="ml-auto mr-auto"><img src="/img/icons/ranks/3.png" alt="" className="max-h-8 ml-auto mr-auto"/></td>
                    </tr>
                    <tr className="">
                        <td className="ml-auto mr-auto">222</td>
                        <td className="">Ded</td>
                        <td className="">1247</td>
                        <td className="ml-auto mr-auto"><img src="/img/icons/ranks/1.png" alt="" className="max-h-8 ml-auto mr-auto"/></td>
                    </tr>
                    <tr className="">
                        <td className="ml-auto mr-auto">223</td>
                        <td className="">Ded</td>
                        <td className="">1247</td>
                        <td className="ml-auto mr-auto"><img src="/img/icons/ranks/0.png" alt="" className="max-h-8 ml-auto mr-auto"/></td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div className="grid grid-cols-3 grid-rows-2 col-span-3 -ml-2 sm:ml-0 space-x-2 space-y-2">
                <div className="col-span-1 bg-zinc-800 rounded-lg border border-opacity-50 border-purple-800 bg-opacity-30 shadow-sm shadow-purple-800 text-lg text-white text-slate-400 ml-2 mt-2 pb-2">
                    <div className="text-2xl text-left ml-3 mt-1 font-sans">12</div>
                    <div className="text-sm text-left ml-3 opacity-50">2.86%</div>
                    <div className="text-sm text-left ml-3">Прострелом</div>
                </div>
                <div className="col-span-1 bg-zinc-800 rounded-lg border border-opacity-50 border-purple-800 bg-opacity-30 shadow-sm shadow-purple-800 text-lg text-white text-slate-400 pb-2">
                    <div className="text-2xl text-left ml-3 mt-1 font-sans">32</div>
                    <div className="text-sm text-left ml-3 opacity-50">7.64%</div>
                    <div className="text-sm text-left ml-3">Без прицела</div>
                </div>
                <div className="col-span-1 bg-zinc-800 rounded-lg border border-opacity-50 border-purple-800 bg-opacity-30 shadow-sm shadow-purple-800 text-lg text-white text-slate-400 pb-2">
                    <div className="text-2xl text-left ml-3 mt-1 font-sans">74</div>
                    <div className="text-sm text-left ml-3 opacity-50">17.66%</div>
                    <div className="text-sm text-left ml-3">На бегу</div>
                </div>
                <div className="col-span-1 bg-zinc-800 rounded-lg border border-opacity-50 border-purple-800 bg-opacity-30 shadow-sm shadow-purple-800 text-lg text-white text-slate-400 pb-2">
                    <div className="text-2xl text-left ml-3 mt-1 font-sans">2</div>
                    <div className="text-sm text-left ml-3 opacity-50">0.48%</div>
                    <div className="text-sm text-left ml-3">В прыжке</div>
                </div>
                <div className="col-span-1 bg-zinc-800 rounded-lg border border-opacity-50 border-purple-800 bg-opacity-30 shadow-sm shadow-purple-800 text-lg text-white text-slate-400 pb-2">
                    <div className="text-2xl text-left ml-3 mt-1 font-sans">5</div>
                    <div className="text-sm text-left ml-3 opacity-50">1.19%</div>
                    <div className="text-sm text-left ml-3">С разворота</div>
                </div>
                <div className="col-span-1 bg-zinc-800 rounded-lg border border-opacity-50 border-purple-800 bg-opacity-30 shadow-sm shadow-purple-800 text-lg text-white text-slate-400 pb-2">
                    <div className="text-2xl text-left ml-3 mt-1 font-sans">13</div>
                    <div className="text-sm text-left ml-3 opacity-50">3.1%</div>
                    <div className="text-sm text-left ml-3">Последним пат.</div>
                </div>
            </div>
        </div>
    );
};

export default Statsrv;